<template>
  <v-dialog :value="dialog" persistent scrollable max-width="400px">
    <v-card>
      <v-card-title class="headline sdark">
        <v-layout align-center>
          <v-flex>
          <span class="primary--text text-uppercase font-weight-medium pl-3"
          >System Settings</span>
          </v-flex>
          <v-flex shrink>
            <v-btn color="primary" icon flat @click.native="onClickClose">
              <v-icon>close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md>
          <v-layout row wrap align-center>
            <v-flex xs12>
              <v-alert :value="error" outline color="primary">
                {{ error }}
              </v-alert>
            </v-flex>
            <v-flex xs12>
              <d-form
                  @onSubmit="onClickSubmit"
                  @onCancel="onClickClose"
                  includeCancel
                  v-bind="{ error, status }"
              >
                <!-- km/m <-> m/ft -->
                <div class="pb-3">
                  <sub-header label="Units Settings" dividerClass="ml-0"/>
                </div>
                <v-radio-group
                  row
                  style="margin-top:0px"
                  v-model="systemSettingsFormValues['display_unit']"
                >
                  <v-radio
                    color="primary"
                    key="km"
                    label="km/m"
                    value="metric"
                    checked
                  />
                  <v-radio
                    color="primary"
                    key="mi"
                    label="mi/ft"
                    value="imperial"
                  />
                </v-radio-group>

                <template v-if="isAdmin || isManager">
                  <div class="pb-3">
                    <sub-header label="Network Settings" dividerClass="ml-0"/>
                  </div>
                  <v-tooltip right max-width="430" color="sdark">
                    <DIpInput
                      v-model="systemSettingsFormValues['static_ip']"
                      slot="activator"
                      :rules="ipAddressRules"
                      label="Static IP Address"
                      data-testid="static-ip"
                    />
                    <span>Static IP Address</span>
                  </v-tooltip>
                  <v-tooltip right max-width="430" color="sdark">
                    <DIpInput
                      v-model="systemSettingsFormValues['static_subnet_mask']"
                      slot="activator"
                      :rules="ipAddressRules"
                      :placeholder="false"
                      label="Static Subnet Mask Address"
                      data-testid="static-subnet-mask"
                    />
                    <span>Static Subnet Mask Address</span>
                  </v-tooltip>
                  <v-tooltip right max-width="430" color="sdark">
                    <v-text-field
                        slot="activator"
                        v-model="systemSettingsFormValues['ntp_url']"
                        label="NTP Server"
                        data-testid="ntp-url"
                    />
                    <span>NTP Server URL</span>
                  </v-tooltip>
                </template>
                <template v-if="isAdmin">
                  <div class="pb-3">
                    <sub-header label="Display Parameters" dividerClass="ml-0"/>
                    <v-select
                      slot="activator"
                      label="Customer Logo"
                      v-model="systemSettingsFormValues['logo']"
                      :items="logoList"
                      item-text="name"
                      item-value="key"
                      data-testid="customer-logo"
                    />
                  </div>
                </template>
              </d-form>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {DialogMixin, FormMixin} from '@/components/Mixins'
import DIpInput from '@/components/Base/DIpInput'
import {mapActions, mapGetters, mapState} from "vuex";
import SubHeader from '../Forms/SubHeader'
import { ELogo } from '@/store/modules/system/types'

const props = {
  dialog: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'SystemSettingsForm',
  components: { SubHeader, DIpInput },
  props,
  data() {
    return {
      systemSettingsFormValues: {},
    }
  },
  mixins: [DialogMixin, FormMixin],
  async mounted() {
    this.$store.commit('system/SET_STATUS', null)
    this.$store.commit('system/SET_ERROR', null)

    this.systemSettingsFormValues = { ...this.systemSetting}
  },
  computed: {
    ...mapState('system', ['status', 'error']),
    ...mapGetters('system', ['systemSetting']),
    ...mapGetters('users', ['isAdmin', 'isManager']),
    ipAddressRules() {
      return [this.rules.ipAddress, this.rules.required]
    },
    logoList() {
      return [
        { key: ELogo.NONE, name: 'None' },
        { key: ELogo.BT, name: 'BT' },
        { key: ELogo.TRAKKA, name: 'Trakka' },
        { key: ELogo.EPIRUS, name: 'Epirus' }
      ]
    }
  },
  methods: {
    ...mapActions('system', ['UPDATE_SYSTEM_SETTINGS']),
    onClickClose() {
      this.$emit('onClose')
    },
    async onClickSubmit() {
      this.$store.commit('system/SET_ERROR', null)
      await this.UPDATE_SYSTEM_SETTINGS(this.systemSettingsFormValues)
      if (this.status === 'OK') {
        this.$emit('onClose')
      }
    }
  }
}
</script>
