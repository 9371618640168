<template>
  <v-card data-cy="settings-menu">
    <!-- v-if for lazy loading of license -->
    <v-dialog v-model="showLicense" width="600px" v-if="showLicense">
      <license-card @close="showLicense = false"></license-card>
    </v-dialog>
    <div v-if="showSystemSettingsForm">
      <system-settings-form
          :dialog="showSystemSettingsForm"
          @onClose="hideSystemSettingsDialog"
      ></system-settings-form>
    </div>
    <v-divider></v-divider>
    <v-list subheader>
      <v-subheader>Support</v-subheader>
      <v-list-tile :href="`mailto:${supportEmail}`">
        <v-list-tile-avatar>
          <v-icon small>email</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>Email Support</v-list-tile-sub-title>
          <v-list-tile-title>{{ supportEmail }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-subheader>Feedback</v-subheader>
      <v-list-tile href="mailto:feedback@droneshield.com">
        <v-list-tile-avatar>
          <v-icon small>email</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>Email Feedback</v-list-tile-sub-title>
          <v-list-tile-title>feedback@droneshield.com</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-subheader>About</v-subheader>
      <template v-for="(item, index) in versions">
        <v-list-tile :key="`${index}-version`" :class="devOrRelease ? 'version' : ''">
          <v-list-tile-avatar>
            <v-icon v-if="index === 0" small>info</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-content>
            <v-list-tile-sub-title
            >{{ item.service }}: {{ item.version }}
            </v-list-tile-sub-title
            >
          </v-list-tile-content>
        </v-list-tile>
      </template>
      <br v-if="devOrRelease"/>
      <v-list-tile>
        <v-list-tile-avatar>
          <v-icon small>storage</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>{{ apiUrl }}</v-list-tile-sub-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile @click.stop="showLicense = true">
        <v-list-tile-avatar>
          <v-icon small>book</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-title>View License Agreement</v-list-tile-title>
      </v-list-tile>
      <v-subheader
      >System
        <v-tooltip right>
          <v-btn
              small
              icon
              slot="activator"
              @click="showSystemSettingsDialog"
          >
            <v-icon id="system-settings" small>settings</v-icon>
          </v-btn>
          <span>System Settings</span>
        </v-tooltip>
      </v-subheader>
      <v-list-tile>
        <v-list-tile-avatar>
          <v-icon small :color="apiIcon">fiber_manual_record</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>API Status</v-list-tile-sub-title>
          <v-list-tile-title>{{ apiText }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-avatar>
          <v-icon small :color="socketIcon">fiber_manual_record</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>Realtime Status</v-list-tile-sub-title>
          <v-list-tile-title>{{ socketText }}</v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <!-- CPU -->
      <v-list-tile v-if="systemStats && systemStats.CPUPercentage">
        <v-list-tile-avatar>
          <v-icon small :color="HighCPUUsage">fiber_manual_record</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>CPU Usage</v-list-tile-sub-title>
          <v-list-tile-title>
            {{ systemStats.CPUPercentage }}%
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <!-- RAM -->
      <v-list-tile v-if="systemStats && systemStats.RAMPercentage">
        <v-list-tile-avatar>
          <v-icon small :color="HighRAMUsage">fiber_manual_record</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>RAM Usage</v-list-tile-sub-title>
          <v-list-tile-title
          >{{ systemStats.RAMPercentage }}%
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>

      <!-- diskUsage SPACE -->
      <v-list-tile v-if="systemStats && systemStats.DiskUsage">
        <v-list-tile-avatar>
          <v-icon small :color="diskUsageIcon">fiber_manual_record</v-icon>
        </v-list-tile-avatar>
        <v-list-tile-content>
          <v-list-tile-sub-title>Disk Usage</v-list-tile-sub-title>
          <v-list-tile-title
          >{{ systemStats.DiskUsage.used_percent }}%
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>
      <template v-if="isAuthorized('manager')">
        <v-subheader>Site</v-subheader>
        <v-list-tile :to="{ name: 'sites.new' }" data-cy="nav-add-site" @click="closeMenu">
          <v-list-tile-action>
            <v-icon>add</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>Add New Site</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </template>
    </v-list>
  </v-card>
</template>

<script>
import SystemSettingsForm from '@/components/Dialogs/SystemSettingsForm'
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'
import capitalize from 'lodash/capitalize'

const LicenseCard = () => import('../License')

const props = {}

export default {
  name: 'AboutMenu',
  data: () => ({
    showLicense: false,
    isDemoMode: false,
    showSystemSettingsForm: false,
  }),
  components: {LicenseCard, SystemSettingsForm},
  props,
  computed: {
    ...mapState('sites', ['activeSiteId']),
    ...mapGetters('users', ['isAuthorized', 'isAdmin']),
    ...mapGetters('sites', ['activeSite']),
    ...mapState(['systemStats']),
    ...mapState('radar_perf_stats', ['stats']),
    ...mapState('system', [
      'upgrades',
      'version',
      'apiUrl',
      'environment',
      'supportEmail'
    ]),
    ...mapState('site_warnings', ['site_warnings', 'site_warnings_history']),
    ...mapGetters('system', ['isSocketConnected']),
    ...mapState('drone_mcu_units', 'demo_mode'),

    throttlePercentage() {
      return this.stats?.percent_dropped
          ? (this.stats.percent_dropped * 100).toFixed()
          : 0
    },
    socketIcon() {
      const status = this.isSocketConnected ? 'good' : 'error'
      return this.iconColor(status)
    },
    socketText() {
      return this.isSocketConnected ? 'Connected' : 'Disconnected'
    },
    HighCPUUsage() {
      const status = this.checkStatus(this.systemStats.CPUPercentage)
      return this.iconColor(status)
    },
    HighRAMUsage() {
      const status = this.checkStatus(this.systemStats.RAMPercentage)
      return this.iconColor(status)
    },
    ThrottleAmount() {
      const status = this.checkStatus(this.throttlePercentage)
      return this.iconColor(status)
    },
    apiText() {
      return this.$store.state.connectionStatus ? 'Connected' : 'Disconnected'
    },
    apiIcon() {
      const status = this.$store.state.connectionStatus ? 'good' : 'error'
      return this.iconColor(status)
    },
    diskUsagePercent() {
      return this.systemStats.DiskUsage?.used_percent
    },
    diskUsageIcon() {
      const status = this.checkStatus(this.diskUsagePercent)
      return this.iconColor(status)
    },
    systemStatsWarnings() {
      return (this.systemStats.DiskUsage?.used_percent || 0) >= 80 ||
          this.systemStats.CPUPercentage >= 80 ||
          this.systemStats.RAMPercentage >= 80 ||
          this.throttlePercentage >= 80
    },
    devOrRelease() {
      return this.version.app === "development" || this.version.app === "release"
    },
    versions() {
      let versions = [{service: "Version", version: this.version.app}]

      if (!this.devOrRelease) return versions

      Object.entries(this.version).map(([key, value]) => {
        if (key !== "app") {
          key = key.split("_").map(k => capitalize(k)).join(" ")
          versions.push({service: `- ${key}`, version: value})
        }
      })

      return versions
    },
  },
  methods: {
    ...mapActions('system', {setTheme: 'SET_THEME'}),
    showSystemSettingsDialog() {
      this.showSystemSettingsForm = true
    },
    hideSystemSettingsDialog() {
      this.showSystemSettingsForm = false
    },
    stopPropagation(event) {
      event.stopPropagation()
    },
    ...mapMutations('drone_mcu_units', {
      setDemoMode: 'SET_DEMO_MODE'
    }),
    iconColor(status) {
      switch (status) {
        case 'good':
          return 'success'
        case 'warning':
          return 'primary'
        case 'error':
          return 'error'
        default:
          return 'error'
      }
    },
    checkStatus(percentage) {
      if (percentage >= 50 && percentage < 80) return 'warning'
      if (percentage >= 80) return 'error'
      return 'good'
    },
    closeMenu() {
      this.$bus.$emit('closeSystemSettingsMenu')
    }
  },
  watch: {
    isDemoMode(e) {
      this.setDemoMode(e)
    },
    systemStats: {
      handler: function () {
        this.$emit('update:systemStatsWarning', this.systemStatsWarnings)
      },
      immediate: true
    }
  }
}
</script>

<style>
.themeSelector.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}

.themeSelector.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}

.version {
  height: 20px;
  padding-bottom: 5px;
}
</style>
