


















import VueIp from 'vue-ip'
import VInput from 'vuetify/lib/components/VInput/VInput.js'

const props = {
  value: {
    required: true,
    type: String,
    default: ''
  },
  placeholder: {
    type: Boolean,
    default: true
  }
}

export default {
  name: 'DIpInput',
  extends: VInput,
  props,
  data(): {} {
    return {
      isMounted: false
    }
  },
  components: {
    VueIp
  },
  mounted(): void {
    this.isMounted = true
  },
  methods: {
    onChange(ip, port, valid): void {
      this.$emit('input', ip)
    }
  },
  computed: {
    errors() {
      return this.errorBucket
    },
    active(): boolean {
      if (!this.isMounted) {
        return false
      } else {
        return this.$refs.ipInput.active
      }
    },
    labelClass(): string {
      if (this.active) {
        return 'v-label--active theme--dark caption primary--text'
      } else {
        return 'v-label theme--dark caption'
      }
    }
  }
}
